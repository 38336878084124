import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { Layout, SEO, P, H1, H2, H5, SocialLinks } from "../components"

const PageSizeWrapper = styled.div`
  padding: 80px 0 40px;
  background-color: #efeae1;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  @media (max-width: 767px) {
    padding: 80px 0;
  }
`

const ContactsPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Say hi" image="/studio.jpg" />
      <PageSizeWrapper>
        <div style={{ height: "40px" }}></div>
        <div style={{ marginBottom: "40px" }}>
          <H1 mb={2}>Get in touch</H1>
          <H2 mb={[3, 4]}>Share your ideas with us</H2>
          <P mb={[3, 5]}>
            Largo Rio da Bica, 20
            <br />
            4150-636 Porto — Portugal
            <br />
            +351 939 461 994
          </P>
          <H5 fontSize={16}>
            <a href="mailto:info@327.pt">info@327.pt</a>
          </H5>
        </div>
        <SocialLinks />
      </PageSizeWrapper>
    </Layout>
  )
}

export default ContactsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
